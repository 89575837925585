"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _App = _interopRequireDefault(require("./App"));
var _Character = _interopRequireDefault(require("./Character"));
var _Group = _interopRequireDefault(require("./Group"));
var _RemovedCharacter = _interopRequireDefault(require("./RemovedCharacter"));
var _Role = _interopRequireDefault(require("./Role"));
var _ServiceAccount = _interopRequireDefault(require("./ServiceAccount"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Player model module.
 * @module model/Player
 * @version 2.6.0
 */
class Player {
  /**
   * Constructs a new <code>Player</code>.
   * @alias module:model/Player
   * @param id {Number} 
   * @param name {String} A name for the player.  This is the EVE character name of the current main character or of the last main character if there is currently none.
   */
  constructor(id, name) {
    Player.initialize(this, id, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, name) {
    obj['id'] = id;
    obj['name'] = name;
  }

  /**
   * Constructs a <code>Player</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Player} obj Optional instance to populate.
   * @return {module:model/Player} The populated <code>Player</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Player();
      if (data.hasOwnProperty('serviceAccounts')) {
        obj['serviceAccounts'] = _ApiClient.default.convertToType(data['serviceAccounts'], [_ServiceAccount.default]);
      }
      if (data.hasOwnProperty('characterId')) {
        obj['characterId'] = _ApiClient.default.convertToType(data['characterId'], 'Number');
      }
      if (data.hasOwnProperty('corporationName')) {
        obj['corporationName'] = _ApiClient.default.convertToType(data['corporationName'], 'String');
      }
      if (data.hasOwnProperty('allianceName')) {
        obj['allianceName'] = _ApiClient.default.convertToType(data['allianceName'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], 'String');
      }
      if (data.hasOwnProperty('roles')) {
        obj['roles'] = _ApiClient.default.convertToType(data['roles'], [_Role.default]);
      }
      if (data.hasOwnProperty('characters')) {
        obj['characters'] = _ApiClient.default.convertToType(data['characters'], [_Character.default]);
      }
      if (data.hasOwnProperty('groups')) {
        obj['groups'] = _ApiClient.default.convertToType(data['groups'], [_Group.default]);
      }
      if (data.hasOwnProperty('managerGroups')) {
        obj['managerGroups'] = _ApiClient.default.convertToType(data['managerGroups'], [_Group.default]);
      }
      if (data.hasOwnProperty('managerApps')) {
        obj['managerApps'] = _ApiClient.default.convertToType(data['managerApps'], [_App.default]);
      }
      if (data.hasOwnProperty('removedCharacters')) {
        obj['removedCharacters'] = _ApiClient.default.convertToType(data['removedCharacters'], [_RemovedCharacter.default]);
      }
      if (data.hasOwnProperty('incomingCharacters')) {
        obj['incomingCharacters'] = _ApiClient.default.convertToType(data['incomingCharacters'], [_RemovedCharacter.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Player</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Player</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Player.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    if (data['serviceAccounts']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['serviceAccounts'])) {
        throw new Error("Expected the field `serviceAccounts` to be an array in the JSON data but got " + data['serviceAccounts']);
      }
      // validate the optional field `serviceAccounts` (array)
      for (const item of data['serviceAccounts']) {
        _ServiceAccount.default.validateJSON(item);
      }
      ;
    }
    // ensure the json data is a string
    if (data['corporationName'] && !(typeof data['corporationName'] === 'string' || data['corporationName'] instanceof String)) {
      throw new Error("Expected the field `corporationName` to be a primitive type in the JSON string but got " + data['corporationName']);
    }
    // ensure the json data is a string
    if (data['allianceName'] && !(typeof data['allianceName'] === 'string' || data['allianceName'] instanceof String)) {
      throw new Error("Expected the field `allianceName` to be a primitive type in the JSON string but got " + data['allianceName']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
      throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['roles'])) {
      throw new Error("Expected the field `roles` to be an array in the JSON data but got " + data['roles']);
    }
    if (data['characters']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['characters'])) {
        throw new Error("Expected the field `characters` to be an array in the JSON data but got " + data['characters']);
      }
      // validate the optional field `characters` (array)
      for (const item of data['characters']) {
        _Character.default.validateJSON(item);
      }
      ;
    }
    if (data['groups']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['groups'])) {
        throw new Error("Expected the field `groups` to be an array in the JSON data but got " + data['groups']);
      }
      // validate the optional field `groups` (array)
      for (const item of data['groups']) {
        _Group.default.validateJSON(item);
      }
      ;
    }
    if (data['managerGroups']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['managerGroups'])) {
        throw new Error("Expected the field `managerGroups` to be an array in the JSON data but got " + data['managerGroups']);
      }
      // validate the optional field `managerGroups` (array)
      for (const item of data['managerGroups']) {
        _Group.default.validateJSON(item);
      }
      ;
    }
    if (data['managerApps']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['managerApps'])) {
        throw new Error("Expected the field `managerApps` to be an array in the JSON data but got " + data['managerApps']);
      }
      // validate the optional field `managerApps` (array)
      for (const item of data['managerApps']) {
        _App.default.validateJSON(item);
      }
      ;
    }
    if (data['removedCharacters']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['removedCharacters'])) {
        throw new Error("Expected the field `removedCharacters` to be an array in the JSON data but got " + data['removedCharacters']);
      }
      // validate the optional field `removedCharacters` (array)
      for (const item of data['removedCharacters']) {
        _RemovedCharacter.default.validateJSON(item);
      }
      ;
    }
    if (data['incomingCharacters']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['incomingCharacters'])) {
        throw new Error("Expected the field `incomingCharacters` to be an array in the JSON data but got " + data['incomingCharacters']);
      }
      // validate the optional field `incomingCharacters` (array)
      for (const item of data['incomingCharacters']) {
        _RemovedCharacter.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}
Player.RequiredProperties = ["id", "name"];

/**
 * External service accounts (API: not included by default)
 * @member {Array.<module:model/ServiceAccount>} serviceAccounts
 */
Player.prototype['serviceAccounts'] = undefined;

/**
 * ID of main character (API: not included by default)
 * @member {Number} characterId
 */
Player.prototype['characterId'] = undefined;

/**
 * Corporation of main character (API: not included by default)
 * @member {String} corporationName
 */
Player.prototype['corporationName'] = undefined;

/**
 * Alliance of main character (API: not included by default)
 * @member {String} allianceName
 */
Player.prototype['allianceName'] = undefined;

/**
 * @member {Number} id
 */
Player.prototype['id'] = undefined;

/**
 * A name for the player.  This is the EVE character name of the current main character or of the last main character if there is currently none.
 * @member {String} name
 */
Player.prototype['name'] = undefined;

/**
 * Player account status.
 * @member {module:model/Player.StatusEnum} status
 */
Player.prototype['status'] = undefined;

/**
 * Roles for authorization.
 * @member {Array.<module:model/Role>} roles
 */
Player.prototype['roles'] = undefined;

/**
 * @member {Array.<module:model/Character>} characters
 */
Player.prototype['characters'] = undefined;

/**
 * Group membership.
 * @member {Array.<module:model/Group>} groups
 */
Player.prototype['groups'] = undefined;

/**
 * Manager of groups.
 * @member {Array.<module:model/Group>} managerGroups
 */
Player.prototype['managerGroups'] = undefined;

/**
 * Manager of apps.
 * @member {Array.<module:model/App>} managerApps
 */
Player.prototype['managerApps'] = undefined;

/**
 * Characters that were removed from a player (API: not included by default).
 * @member {Array.<module:model/RemovedCharacter>} removedCharacters
 */
Player.prototype['removedCharacters'] = undefined;

/**
 * Characters that were moved from another player account to this account (API: not included by default).
 * @member {Array.<module:model/RemovedCharacter>} incomingCharacters
 */
Player.prototype['incomingCharacters'] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Player['StatusEnum'] = {
  /**
   * value: "standard"
   * @const
   */
  "standard": "standard",
  /**
   * value: "managed"
   * @const
   */
  "managed": "managed"
};
var _default = exports.default = Player;