"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _EveLogin = _interopRequireDefault(require("./EveLogin"));
var _Group = _interopRequireDefault(require("./Group"));
var _Role = _interopRequireDefault(require("./Role"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The App model module.
 * @module model/App
 * @version 2.6.0
 */
class App {
  /**
   * Constructs a new <code>App</code>.
   * @alias module:model/App
   * @param id {Number} App ID
   * @param name {String} App name
   */
  constructor(id, name) {
    App.initialize(this, id, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, name) {
    obj['id'] = id;
    obj['name'] = name;
  }

  /**
   * Constructs a <code>App</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/App} obj Optional instance to populate.
   * @return {module:model/App} The populated <code>App</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new App();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('roles')) {
        obj['roles'] = _ApiClient.default.convertToType(data['roles'], [_Role.default]);
      }
      if (data.hasOwnProperty('groups')) {
        obj['groups'] = _ApiClient.default.convertToType(data['groups'], [_Group.default]);
      }
      if (data.hasOwnProperty('eveLogins')) {
        obj['eveLogins'] = _ApiClient.default.convertToType(data['eveLogins'], [_EveLogin.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>App</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>App</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of App.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['roles'])) {
      throw new Error("Expected the field `roles` to be an array in the JSON data but got " + data['roles']);
    }
    if (data['groups']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['groups'])) {
        throw new Error("Expected the field `groups` to be an array in the JSON data but got " + data['groups']);
      }
      // validate the optional field `groups` (array)
      for (const item of data['groups']) {
        _Group.default.validateJSON(item);
      }
      ;
    }
    if (data['eveLogins']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['eveLogins'])) {
        throw new Error("Expected the field `eveLogins` to be an array in the JSON data but got " + data['eveLogins']);
      }
      // validate the optional field `eveLogins` (array)
      for (const item of data['eveLogins']) {
        _EveLogin.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}
App.RequiredProperties = ["id", "name"];

/**
 * App ID
 * @member {Number} id
 */
App.prototype['id'] = undefined;

/**
 * App name
 * @member {String} name
 */
App.prototype['name'] = undefined;

/**
 * Roles for authorization.
 * @member {Array.<module:model/Role>} roles
 */
App.prototype['roles'] = undefined;

/**
 * Groups the app can see.
 * @member {Array.<module:model/Group>} groups
 */
App.prototype['groups'] = undefined;

/**
 * @member {Array.<module:model/EveLogin>} eveLogins
 */
App.prototype['eveLogins'] = undefined;
var _default = exports.default = App;