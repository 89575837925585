"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CharacterNameChange = _interopRequireDefault(require("./CharacterNameChange"));
var _Corporation = _interopRequireDefault(require("./Corporation"));
var _EsiToken = _interopRequireDefault(require("./EsiToken"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Character model module.
 * @module model/Character
 * @version 2.6.0
 */
class Character {
  /**
   * Constructs a new <code>Character</code>.
   * @alias module:model/Character
   * @param id {Number} EVE character ID.
   * @param name {String} EVE character name.
   */
  constructor(id, name) {
    Character.initialize(this, id, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, name) {
    obj['id'] = id;
    obj['name'] = name;
  }

  /**
   * Constructs a <code>Character</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Character} obj Optional instance to populate.
   * @return {module:model/Character} The populated <code>Character</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Character();
      if (data.hasOwnProperty('validToken')) {
        obj['validToken'] = _ApiClient.default.convertToType(data['validToken'], 'Boolean');
      }
      if (data.hasOwnProperty('validTokenTime')) {
        obj['validTokenTime'] = _ApiClient.default.convertToType(data['validTokenTime'], 'Date');
      }
      if (data.hasOwnProperty('tokenLastChecked')) {
        obj['tokenLastChecked'] = _ApiClient.default.convertToType(data['tokenLastChecked'], 'Date');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('main')) {
        obj['main'] = _ApiClient.default.convertToType(data['main'], 'Boolean');
      }
      if (data.hasOwnProperty('esiTokens')) {
        obj['esiTokens'] = _ApiClient.default.convertToType(data['esiTokens'], [_EsiToken.default]);
      }
      if (data.hasOwnProperty('created')) {
        obj['created'] = _ApiClient.default.convertToType(data['created'], 'Date');
      }
      if (data.hasOwnProperty('lastUpdate')) {
        obj['lastUpdate'] = _ApiClient.default.convertToType(data['lastUpdate'], 'Date');
      }
      if (data.hasOwnProperty('corporation')) {
        obj['corporation'] = _Corporation.default.constructFromObject(data['corporation']);
      }
      if (data.hasOwnProperty('characterNameChanges')) {
        obj['characterNameChanges'] = _ApiClient.default.convertToType(data['characterNameChanges'], [_CharacterNameChange.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Character</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Character</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Character.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    if (data['esiTokens']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['esiTokens'])) {
        throw new Error("Expected the field `esiTokens` to be an array in the JSON data but got " + data['esiTokens']);
      }
      // validate the optional field `esiTokens` (array)
      for (const item of data['esiTokens']) {
        _EsiToken.default.validateJSON(item);
      }
      ;
    }
    // validate the optional field `corporation`
    if (data['corporation']) {
      // data not null
      _Corporation.default.validateJSON(data['corporation']);
    }
    if (data['characterNameChanges']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['characterNameChanges'])) {
        throw new Error("Expected the field `characterNameChanges` to be an array in the JSON data but got " + data['characterNameChanges']);
      }
      // validate the optional field `characterNameChanges` (array)
      for (const item of data['characterNameChanges']) {
        _CharacterNameChange.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}
Character.RequiredProperties = ["id", "name"];

/**
 * Shows if character's default refresh token is valid or not. This is null if there is no refresh token (EVE SSOv1 only) or a valid token but without scopes (SSOv2).
 * @member {Boolean} validToken
 */
Character.prototype['validToken'] = undefined;

/**
 * Date and time when the valid token property of the default token was last changed.
 * @member {Date} validTokenTime
 */
Character.prototype['validTokenTime'] = undefined;

/**
 * Date and time when the default token was last checked.
 * @member {Date} tokenLastChecked
 */
Character.prototype['tokenLastChecked'] = undefined;

/**
 * EVE character ID.
 * @member {Number} id
 */
Character.prototype['id'] = undefined;

/**
 * EVE character name.
 * @member {String} name
 */
Character.prototype['name'] = undefined;

/**
 * @member {Boolean} main
 */
Character.prototype['main'] = undefined;

/**
 * ESI tokens of the character (API: not included by default).
 * @member {Array.<module:model/EsiToken>} esiTokens
 */
Character.prototype['esiTokens'] = undefined;

/**
 * @member {Date} created
 */
Character.prototype['created'] = undefined;

/**
 * Last ESI update.
 * @member {Date} lastUpdate
 */
Character.prototype['lastUpdate'] = undefined;

/**
 * @member {module:model/Corporation} corporation
 */
Character.prototype['corporation'] = undefined;

/**
 * List of previous character names (API: not included by default).
 * @member {Array.<module:model/CharacterNameChange>} characterNameChanges
 */
Character.prototype['characterNameChanges'] = undefined;
var _default = exports.default = Character;