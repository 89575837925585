"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PlayerLoginStatistics model module.
 * @module model/PlayerLoginStatistics
 * @version 2.6.0
 */
class PlayerLoginStatistics {
  /**
   * Constructs a new <code>PlayerLoginStatistics</code>.
   * @alias module:model/PlayerLoginStatistics
   * @param uniqueLogins {Number} 
   * @param totalLogins {Number} 
   * @param year {Number} 
   * @param month {Number} 
   */
  constructor(uniqueLogins, totalLogins, year, month) {
    PlayerLoginStatistics.initialize(this, uniqueLogins, totalLogins, year, month);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, uniqueLogins, totalLogins, year, month) {
    obj['unique_logins'] = uniqueLogins;
    obj['total_logins'] = totalLogins;
    obj['year'] = year;
    obj['month'] = month;
  }

  /**
   * Constructs a <code>PlayerLoginStatistics</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PlayerLoginStatistics} obj Optional instance to populate.
   * @return {module:model/PlayerLoginStatistics} The populated <code>PlayerLoginStatistics</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PlayerLoginStatistics();
      if (data.hasOwnProperty('unique_logins')) {
        obj['unique_logins'] = _ApiClient.default.convertToType(data['unique_logins'], 'Number');
      }
      if (data.hasOwnProperty('total_logins')) {
        obj['total_logins'] = _ApiClient.default.convertToType(data['total_logins'], 'Number');
      }
      if (data.hasOwnProperty('year')) {
        obj['year'] = _ApiClient.default.convertToType(data['year'], 'Number');
      }
      if (data.hasOwnProperty('month')) {
        obj['month'] = _ApiClient.default.convertToType(data['month'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PlayerLoginStatistics</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PlayerLoginStatistics</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of PlayerLoginStatistics.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
PlayerLoginStatistics.RequiredProperties = ["unique_logins", "total_logins", "year", "month"];

/**
 * @member {Number} unique_logins
 */
PlayerLoginStatistics.prototype['unique_logins'] = undefined;

/**
 * @member {Number} total_logins
 */
PlayerLoginStatistics.prototype['total_logins'] = undefined;

/**
 * @member {Number} year
 */
PlayerLoginStatistics.prototype['year'] = undefined;

/**
 * @member {Number} month
 */
PlayerLoginStatistics.prototype['month'] = undefined;
var _default = exports.default = PlayerLoginStatistics;