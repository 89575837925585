"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The EveLogin model module.
 * @module model/EveLogin
 * @version 2.6.0
 */
class EveLogin {
  /**
   * Constructs a new <code>EveLogin</code>.
   * @alias module:model/EveLogin
   * @param id {Number} 
   * @param name {String} Names starting with 'core.' are reserved for internal use.
   * @param description {String} 
   * @param esiScopes {String} 
   * @param eveRoles {Array.<String>} Maximum length of all roles separated by comma: 1024.
   */
  constructor(id, name, description, esiScopes, eveRoles) {
    EveLogin.initialize(this, id, name, description, esiScopes, eveRoles);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, name, description, esiScopes, eveRoles) {
    obj['id'] = id;
    obj['name'] = name;
    obj['description'] = description;
    obj['esiScopes'] = esiScopes;
    obj['eveRoles'] = eveRoles;
  }

  /**
   * Constructs a <code>EveLogin</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EveLogin} obj Optional instance to populate.
   * @return {module:model/EveLogin} The populated <code>EveLogin</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EveLogin();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('esiScopes')) {
        obj['esiScopes'] = _ApiClient.default.convertToType(data['esiScopes'], 'String');
      }
      if (data.hasOwnProperty('eveRoles')) {
        obj['eveRoles'] = _ApiClient.default.convertToType(data['eveRoles'], ['String']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>EveLogin</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>EveLogin</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of EveLogin.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['esiScopes'] && !(typeof data['esiScopes'] === 'string' || data['esiScopes'] instanceof String)) {
      throw new Error("Expected the field `esiScopes` to be a primitive type in the JSON string but got " + data['esiScopes']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['eveRoles'])) {
      throw new Error("Expected the field `eveRoles` to be an array in the JSON data but got " + data['eveRoles']);
    }
    return true;
  }
}
EveLogin.RequiredProperties = ["id", "name", "description", "esiScopes", "eveRoles"];

/**
 * @member {Number} id
 */
EveLogin.prototype['id'] = undefined;

/**
 * Names starting with 'core.' are reserved for internal use.
 * @member {String} name
 */
EveLogin.prototype['name'] = undefined;

/**
 * @member {String} description
 */
EveLogin.prototype['description'] = undefined;

/**
 * @member {String} esiScopes
 */
EveLogin.prototype['esiScopes'] = undefined;

/**
 * Maximum length of all roles separated by comma: 1024.
 * @member {Array.<String>} eveRoles
 */
EveLogin.prototype['eveRoles'] = undefined;
var _default = exports.default = EveLogin;