"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The HourlyAppRequests model module.
 * @module model/HourlyAppRequests
 * @version 2.6.0
 */
class HourlyAppRequests {
  /**
   * Constructs a new <code>HourlyAppRequests</code>.
   * @alias module:model/HourlyAppRequests
   * @param appId {Number} 
   * @param appName {String} 
   * @param requests {Number} 
   * @param year {Number} 
   * @param month {Number} 
   * @param dayOfMonth {Number} 
   * @param hour {Number} 
   */
  constructor(appId, appName, requests, year, month, dayOfMonth, hour) {
    HourlyAppRequests.initialize(this, appId, appName, requests, year, month, dayOfMonth, hour);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, appId, appName, requests, year, month, dayOfMonth, hour) {
    obj['app_id'] = appId;
    obj['app_name'] = appName;
    obj['requests'] = requests;
    obj['year'] = year;
    obj['month'] = month;
    obj['day_of_month'] = dayOfMonth;
    obj['hour'] = hour;
  }

  /**
   * Constructs a <code>HourlyAppRequests</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HourlyAppRequests} obj Optional instance to populate.
   * @return {module:model/HourlyAppRequests} The populated <code>HourlyAppRequests</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HourlyAppRequests();
      if (data.hasOwnProperty('app_id')) {
        obj['app_id'] = _ApiClient.default.convertToType(data['app_id'], 'Number');
      }
      if (data.hasOwnProperty('app_name')) {
        obj['app_name'] = _ApiClient.default.convertToType(data['app_name'], 'String');
      }
      if (data.hasOwnProperty('requests')) {
        obj['requests'] = _ApiClient.default.convertToType(data['requests'], 'Number');
      }
      if (data.hasOwnProperty('year')) {
        obj['year'] = _ApiClient.default.convertToType(data['year'], 'Number');
      }
      if (data.hasOwnProperty('month')) {
        obj['month'] = _ApiClient.default.convertToType(data['month'], 'Number');
      }
      if (data.hasOwnProperty('day_of_month')) {
        obj['day_of_month'] = _ApiClient.default.convertToType(data['day_of_month'], 'Number');
      }
      if (data.hasOwnProperty('hour')) {
        obj['hour'] = _ApiClient.default.convertToType(data['hour'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>HourlyAppRequests</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>HourlyAppRequests</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of HourlyAppRequests.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['app_name'] && !(typeof data['app_name'] === 'string' || data['app_name'] instanceof String)) {
      throw new Error("Expected the field `app_name` to be a primitive type in the JSON string but got " + data['app_name']);
    }
    return true;
  }
}
HourlyAppRequests.RequiredProperties = ["app_id", "app_name", "requests", "year", "month", "day_of_month", "hour"];

/**
 * @member {Number} app_id
 */
HourlyAppRequests.prototype['app_id'] = undefined;

/**
 * @member {String} app_name
 */
HourlyAppRequests.prototype['app_name'] = undefined;

/**
 * @member {Number} requests
 */
HourlyAppRequests.prototype['requests'] = undefined;

/**
 * @member {Number} year
 */
HourlyAppRequests.prototype['year'] = undefined;

/**
 * @member {Number} month
 */
HourlyAppRequests.prototype['month'] = undefined;

/**
 * @member {Number} day_of_month
 */
HourlyAppRequests.prototype['day_of_month'] = undefined;

/**
 * @member {Number} hour
 */
HourlyAppRequests.prototype['hour'] = undefined;
var _default = exports.default = HourlyAppRequests;