"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _App = _interopRequireDefault(require("../model/App"));
var _Player = _interopRequireDefault(require("../model/Player"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* App service.
* @module api/AppApi
* @version 2.6.0
*/
class AppApi {
  /**
  * Constructs a new AppApi. 
  * @alias module:api/AppApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addManager operation.
   * @callback module:api/AppApi~addManagerCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Assign a player as manager to an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {Number} pid ID of the player.
   * @param {module:api/AppApi~addManagerCallback} callback The callback function, accepting three arguments: error, data, response
   */
  addManager(id, pid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addManager");
    }
    // verify the required parameter 'pid' is set
    if (pid === undefined || pid === null) {
      throw new Error("Missing the required parameter 'pid' when calling addManager");
    }
    let pathParams = {
      'id': id,
      'pid': pid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/add-manager/{pid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addRole operation.
   * @callback module:api/AppApi~addRoleCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add a role to the app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {module:model/String} name Name of the role.
   * @param {module:api/AppApi~addRoleCallback} callback The callback function, accepting three arguments: error, data, response
   */
  addRole(id, name, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addRole");
    }
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling addRole");
    }
    let pathParams = {
      'id': id,
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/add-role/{name}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the callDelete operation.
   * @callback module:api/AppApi~callDeleteCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {module:api/AppApi~callDeleteCallback} callback The callback function, accepting three arguments: error, data, response
   */
  callDelete(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling callDelete");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/delete', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the changeSecret operation.
   * @callback module:api/AppApi~changeSecretCallback
   * @param {String} error Error message, if any.
   * @param {String} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Generates a new application secret. The new secret is returned, it cannot be retrieved afterwards.
   * Needs role: app-manager
   * @param {Number} id ID of the app.
   * @param {module:api/AppApi~changeSecretCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link String}
   */
  changeSecret(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling changeSecret");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';
    return this.apiClient.callApi('/user/app/{id}/change-secret', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the create operation.
   * @callback module:api/AppApi~createCallback
   * @param {String} error Error message, if any.
   * @param {module:model/App} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create an app.
   * Needs role: app-admin<br>Generates a random secret that must be changed by an app manager.
   * @param {String} name Name of the app.
   * @param {module:api/AppApi~createCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/App}
   */
  create(name, callback) {
    let postBody = null;
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling create");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'name': name
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _App.default;
    return this.apiClient.callApi('/user/app/create', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the managers operation.
   * @callback module:api/AppApi~managersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all managers of an app.
   * Needs role: app-admin
   * @param {Number} id App ID.
   * @param {module:api/AppApi~managersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  managers(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling managers");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/app/{id}/managers', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeManager operation.
   * @callback module:api/AppApi~removeManagerCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a manager (player) from an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {Number} pid ID of the player.
   * @param {module:api/AppApi~removeManagerCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeManager(id, pid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling removeManager");
    }
    // verify the required parameter 'pid' is set
    if (pid === undefined || pid === null) {
      throw new Error("Missing the required parameter 'pid' when calling removeManager");
    }
    let pathParams = {
      'id': id,
      'pid': pid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/remove-manager/{pid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeRole operation.
   * @callback module:api/AppApi~removeRoleCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a role from an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {module:model/String} name Name of the role.
   * @param {module:api/AppApi~removeRoleCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeRole(id, name, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling removeRole");
    }
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling removeRole");
    }
    let pathParams = {
      'id': id,
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/remove-role/{name}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the rename operation.
   * @callback module:api/AppApi~renameCallback
   * @param {String} error Error message, if any.
   * @param {module:model/App} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Rename an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {String} name New name for the app.
   * @param {module:api/AppApi~renameCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/App}
   */
  rename(id, name, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling rename");
    }
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling rename");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'name': name
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _App.default;
    return this.apiClient.callApi('/user/app/{id}/rename', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the show operation.
   * @callback module:api/AppApi~showCallback
   * @param {String} error Error message, if any.
   * @param {module:model/App} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Shows app information.
   * Needs role: app-admin, app-manager<br>Managers can only see groups of their own apps.
   * @param {Number} id App ID.
   * @param {module:api/AppApi~showCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/App}
   */
  show(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling show");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _App.default;
    return this.apiClient.callApi('/user/app/{id}/show', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userAppAddEveLogin operation.
   * @callback module:api/AppApi~userAppAddEveLoginCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add an EVE login to an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {Number} eveLoginId ID of the EVE login.
   * @param {module:api/AppApi~userAppAddEveLoginCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userAppAddEveLogin(id, eveLoginId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userAppAddEveLogin");
    }
    // verify the required parameter 'eveLoginId' is set
    if (eveLoginId === undefined || eveLoginId === null) {
      throw new Error("Missing the required parameter 'eveLoginId' when calling userAppAddEveLogin");
    }
    let pathParams = {
      'id': id,
      'eveLoginId': eveLoginId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/add-eve-login/{eveLoginId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userAppAddGroup operation.
   * @callback module:api/AppApi~userAppAddGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add a group to an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {Number} gid ID of the group.
   * @param {module:api/AppApi~userAppAddGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userAppAddGroup(id, gid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userAppAddGroup");
    }
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling userAppAddGroup");
    }
    let pathParams = {
      'id': id,
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/add-group/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userAppAll operation.
   * @callback module:api/AppApi~userAppAllCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/App>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all apps.
   * Needs role: app-admin
   * @param {module:api/AppApi~userAppAllCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/App>}
   */
  userAppAll(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_App.default];
    return this.apiClient.callApi('/user/app/all', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userAppRemoveEveLogin operation.
   * @callback module:api/AppApi~userAppRemoveEveLoginCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove an EVE login from an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {Number} eveLoginId ID of the EVE login.
   * @param {module:api/AppApi~userAppRemoveEveLoginCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userAppRemoveEveLogin(id, eveLoginId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userAppRemoveEveLogin");
    }
    // verify the required parameter 'eveLoginId' is set
    if (eveLoginId === undefined || eveLoginId === null) {
      throw new Error("Missing the required parameter 'eveLoginId' when calling userAppRemoveEveLogin");
    }
    let pathParams = {
      'id': id,
      'eveLoginId': eveLoginId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/remove-eve-login/{eveLoginId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userAppRemoveGroup operation.
   * @callback module:api/AppApi~userAppRemoveGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a group from an app.
   * Needs role: app-admin
   * @param {Number} id ID of the app.
   * @param {Number} gid ID of the group.
   * @param {module:api/AppApi~userAppRemoveGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userAppRemoveGroup(id, gid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userAppRemoveGroup");
    }
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling userAppRemoveGroup");
    }
    let pathParams = {
      'id': id,
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/app/{id}/remove-group/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = AppApi;