"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Plugin = _interopRequireDefault(require("../model/Plugin"));
var _PluginConfigurationDatabase = _interopRequireDefault(require("../model/PluginConfigurationDatabase"));
var _PluginConfigurationFile = _interopRequireDefault(require("../model/PluginConfigurationFile"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* PluginAdmin service.
* @module api/PluginAdminApi
* @version 2.6.0
*/
class PluginAdminApi {
  /**
  * Constructs a new PluginAdminApi. 
  * @alias module:api/PluginAdminApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the pluginAdminConfigurations operation.
   * @callback module:api/PluginAdminApi~pluginAdminConfigurationsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/PluginConfigurationFile>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns data from plugin.yml files and their directory.
   * Needs role: plugin-admin
   * @param {module:api/PluginAdminApi~pluginAdminConfigurationsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/PluginConfigurationFile>}
   */
  pluginAdminConfigurations(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_PluginConfigurationFile.default];
    return this.apiClient.callApi('/user/plugin-admin/configurations', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the pluginAdminCreate operation.
   * @callback module:api/PluginAdminApi~pluginAdminCreateCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Plugin} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Creates a plugin.
   * Needs role: plugin-admin
   * @param {String} name Name of the plugin.
   * @param {module:api/PluginAdminApi~pluginAdminCreateCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Plugin}
   */
  pluginAdminCreate(name, callback) {
    let postBody = null;
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling pluginAdminCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'name': name
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _Plugin.default;
    return this.apiClient.callApi('/user/plugin-admin/create', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the pluginAdminDelete operation.
   * @callback module:api/PluginAdminApi~pluginAdminDeleteCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Deletes a plugin.
   * Needs role: plugin-admin
   * @param {Number} id ID of the plugin.
   * @param {module:api/PluginAdminApi~pluginAdminDeleteCallback} callback The callback function, accepting three arguments: error, data, response
   */
  pluginAdminDelete(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling pluginAdminDelete");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/plugin-admin/{id}/delete', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the pluginAdminGet operation.
   * @callback module:api/PluginAdminApi~pluginAdminGetCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Plugin} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns plugin.
   * Needs role: plugin-admin
   * @param {Number} id ID of the plugin.
   * @param {module:api/PluginAdminApi~pluginAdminGetCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Plugin}
   */
  pluginAdminGet(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling pluginAdminGet");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Plugin.default;
    return this.apiClient.callApi('/user/plugin-admin/{id}/get', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the pluginAdminList operation.
   * @callback module:api/PluginAdminApi~pluginAdminListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Plugin>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Lists all plugins.
   * Needs role: plugin-admin
   * @param {module:api/PluginAdminApi~pluginAdminListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Plugin>}
   */
  pluginAdminList(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Plugin.default];
    return this.apiClient.callApi('/user/plugin-admin/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the pluginAdminRename operation.
   * @callback module:api/PluginAdminApi~pluginAdminRenameCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Plugin} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Renames a plugin.
   * Needs role: plugin-admin
   * @param {Number} id ID of the plugin.
   * @param {String} name New name for the plugin.
   * @param {module:api/PluginAdminApi~pluginAdminRenameCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Plugin}
   */
  pluginAdminRename(id, name, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling pluginAdminRename");
    }
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling pluginAdminRename");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'name': name
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _Plugin.default;
    return this.apiClient.callApi('/user/plugin-admin/{id}/rename', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the pluginAdminSaveConfiguration operation.
   * @callback module:api/PluginAdminApi~pluginAdminSaveConfigurationCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Saves the plugin configuration.
   * Needs role: plugin-admin
   * @param {Number} id ID of the plugin.
   * @param {Object} opts Optional parameters
   * @param {module:model/PluginConfigurationDatabase} [configuration] 
   * @param {module:api/PluginAdminApi~pluginAdminSaveConfigurationCallback} callback The callback function, accepting three arguments: error, data, response
   */
  pluginAdminSaveConfiguration(id, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling pluginAdminSaveConfiguration");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'configuration': opts['configuration']
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/plugin-admin/{id}/save-configuration', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = PluginAdminApi;