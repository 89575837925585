"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Character = _interopRequireDefault(require("./Character"));
var _EsiLocation = _interopRequireDefault(require("./EsiLocation"));
var _EsiType = _interopRequireDefault(require("./EsiType"));
var _Player = _interopRequireDefault(require("./Player"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CorporationMember model module.
 * @module model/CorporationMember
 * @version 2.6.0
 */
class CorporationMember {
  /**
   * Constructs a new <code>CorporationMember</code>.
   * The player property contains only id and name, character does not contain corporation.
   * @alias module:model/CorporationMember
   * @param id {Number} EVE Character ID.
   * @param name {String} EVE Character name.
   */
  constructor(id, name) {
    CorporationMember.initialize(this, id, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, name) {
    obj['id'] = id;
    obj['name'] = name;
  }

  /**
   * Constructs a <code>CorporationMember</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CorporationMember} obj Optional instance to populate.
   * @return {module:model/CorporationMember} The populated <code>CorporationMember</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CorporationMember();
      if (data.hasOwnProperty('player')) {
        obj['player'] = _Player.default.constructFromObject(data['player']);
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('location')) {
        obj['location'] = _EsiLocation.default.constructFromObject(data['location']);
      }
      if (data.hasOwnProperty('logoffDate')) {
        obj['logoffDate'] = _ApiClient.default.convertToType(data['logoffDate'], 'Date');
      }
      if (data.hasOwnProperty('logonDate')) {
        obj['logonDate'] = _ApiClient.default.convertToType(data['logonDate'], 'Date');
      }
      if (data.hasOwnProperty('shipType')) {
        obj['shipType'] = _EsiType.default.constructFromObject(data['shipType']);
      }
      if (data.hasOwnProperty('startDate')) {
        obj['startDate'] = _ApiClient.default.convertToType(data['startDate'], 'Date');
      }
      if (data.hasOwnProperty('character')) {
        obj['character'] = _Character.default.constructFromObject(data['character']);
      }
      if (data.hasOwnProperty('missingCharacterMailSentDate')) {
        obj['missingCharacterMailSentDate'] = _ApiClient.default.convertToType(data['missingCharacterMailSentDate'], 'Date');
      }
      if (data.hasOwnProperty('missingCharacterMailSentResult')) {
        obj['missingCharacterMailSentResult'] = _ApiClient.default.convertToType(data['missingCharacterMailSentResult'], 'String');
      }
      if (data.hasOwnProperty('missingCharacterMailSentNumber')) {
        obj['missingCharacterMailSentNumber'] = _ApiClient.default.convertToType(data['missingCharacterMailSentNumber'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CorporationMember</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CorporationMember</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CorporationMember.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `player`
    if (data['player']) {
      // data not null
      _Player.default.validateJSON(data['player']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // validate the optional field `location`
    if (data['location']) {
      // data not null
      _EsiLocation.default.validateJSON(data['location']);
    }
    // validate the optional field `shipType`
    if (data['shipType']) {
      // data not null
      _EsiType.default.validateJSON(data['shipType']);
    }
    // validate the optional field `character`
    if (data['character']) {
      // data not null
      _Character.default.validateJSON(data['character']);
    }
    // ensure the json data is a string
    if (data['missingCharacterMailSentResult'] && !(typeof data['missingCharacterMailSentResult'] === 'string' || data['missingCharacterMailSentResult'] instanceof String)) {
      throw new Error("Expected the field `missingCharacterMailSentResult` to be a primitive type in the JSON string but got " + data['missingCharacterMailSentResult']);
    }
    return true;
  }
}
CorporationMember.RequiredProperties = ["id", "name"];

/**
 * @member {module:model/Player} player
 */
CorporationMember.prototype['player'] = undefined;

/**
 * EVE Character ID.
 * @member {Number} id
 */
CorporationMember.prototype['id'] = undefined;

/**
 * EVE Character name.
 * @member {String} name
 */
CorporationMember.prototype['name'] = undefined;

/**
 * @member {module:model/EsiLocation} location
 */
CorporationMember.prototype['location'] = undefined;

/**
 * @member {Date} logoffDate
 */
CorporationMember.prototype['logoffDate'] = undefined;

/**
 * @member {Date} logonDate
 */
CorporationMember.prototype['logonDate'] = undefined;

/**
 * @member {module:model/EsiType} shipType
 */
CorporationMember.prototype['shipType'] = undefined;

/**
 * @member {Date} startDate
 */
CorporationMember.prototype['startDate'] = undefined;

/**
 * @member {module:model/Character} character
 */
CorporationMember.prototype['character'] = undefined;

/**
 * Date and time of the last sent mail.
 * @member {Date} missingCharacterMailSentDate
 */
CorporationMember.prototype['missingCharacterMailSentDate'] = undefined;

/**
 * Result of the last sent mail (OK, Blocked, CSPA charge > 0)
 * @member {String} missingCharacterMailSentResult
 */
CorporationMember.prototype['missingCharacterMailSentResult'] = undefined;

/**
 * Number of mails sent, is reset when the character is added.
 * @member {Number} missingCharacterMailSentNumber
 */
CorporationMember.prototype['missingCharacterMailSentNumber'] = undefined;
var _default = exports.default = CorporationMember;