"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Character = _interopRequireDefault(require("../model/Character"));
var _SearchResult = _interopRequireDefault(require("../model/SearchResult"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Character service.
* @module api/CharacterApi
* @version 2.6.0
*/
class CharacterApi {
  /**
  * Constructs a new CharacterApi. 
  * @alias module:api/CharacterApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the findCharacter operation.
   * @callback module:api/CharacterApi~findCharacterCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/SearchResult>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns a list of characters (together with the name of the player account/main character) that matches the name (partial matching).
   * Needs role: user-admin, user-manager, user-chars
   * @param {String} name Name of the character (min. 3 characters).
   * @param {Object} opts Optional parameters
   * @param {module:model/String} [currentOnly] Do not include old character names or moved characters. Defaults to false.
   * @param {module:model/String} [plugin] Include results from active service plugins. Defaults to false
   * @param {module:api/CharacterApi~findCharacterCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/SearchResult>}
   */
  findCharacter(name, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling findCharacter");
    }
    let pathParams = {
      'name': name
    };
    let queryParams = {
      'currentOnly': opts['currentOnly'],
      'plugin': opts['plugin']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_SearchResult.default];
    return this.apiClient.callApi('/user/character/find-character/{name}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the findPlayer operation.
   * @callback module:api/CharacterApi~findPlayerCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/SearchResult>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Return a list of players that matches the main character name (partial matching).
   * Needs role: group-manager
   * @param {String} name Name of the main character (min. 3 characters).
   * @param {module:api/CharacterApi~findPlayerCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/SearchResult>}
   */
  findPlayer(name, callback) {
    let postBody = null;
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling findPlayer");
    }
    let pathParams = {
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_SearchResult.default];
    return this.apiClient.callApi('/user/character/find-player/{name}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the update operation.
   * @callback module:api/CharacterApi~updateCallback
   * @param {String} error Error message, if any.
   * @param {Number} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update a character with data from ESI.
   * Needs role: user to update own characters or user-admin, user-manager, group-admin, app-admin, user-chars, tracking or watchlist to update any character.<br>It also updates groups and verifies the OAuth token.
   * @param {Number} id EVE character ID.
   * @param {module:api/CharacterApi~updateCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Number}
   */
  update(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Number';
    return this.apiClient.callApi('/user/character/{id}/update', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userCharacterAdd operation.
   * @callback module:api/CharacterApi~userCharacterAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add an EVE character to the database on a new account.
   * Needs role: user-admin
   * @param {Number} id EVE character ID.
   * @param {module:api/CharacterApi~userCharacterAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userCharacterAdd(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userCharacterAdd");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/character/add/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userCharacterShow operation.
   * @callback module:api/CharacterApi~userCharacterShowCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Character} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Return the logged-in EVE character.
   * Needs role: user
   * @param {module:api/CharacterApi~userCharacterShowCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Character}
   */
  userCharacterShow(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Character.default;
    return this.apiClient.callApi('/user/character/show', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = CharacterApi;